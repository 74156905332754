@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after  {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

[data-amplify-authenticator][data-variation=modal] {
    background-color: #fff !important;
}

[data-amplify-authenticator] [data-amplify-router] {
    background-color: #fff !important;
    border-color: #fff !important;
    border-radius: 5px !important;
}

.amplify-button--primary {
    background-color: #4C5981 !important;
}
.amplify-button--link {
    color: #4C5981 !important;
}

.amplify-button--link:hover {
    background-color: #C2C2C2 !important;
}